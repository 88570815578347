/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}
.textAreaDiv {
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #555;
  padding: 5px;
  line-height: 24px;
  height: 200px;
  overflow-x: scroll;
  span {
    background-color: #eee;
    display: inline-block;
    padding: 4px;
    line-height: 16px;
    border-radius: 3px;
  }
}
hr {
  color: #000;
}
ng-select.form-aligne {
  .ng-select-container {
    height: 38px !important;
  }
}
input[type="file"]::file-selector-button {
  color: #333;
}
.custom-datatable-label {
  .counter {
    p {
      margin-top: 0.5rem;
      margin-bottom: 0;
      line-height: 0.7rem;
      b {
        color: #aaa;
        font-size: 0.7rem;
      }
    }
  }
}
input.showinvalid.ng-invalid.ng-touched {
  border: 1px solid red;
}
input.required.ng-invalid{
  position: relative;
  &:after {
    top: 0px;
    left: -10px;
    content: "*";
    position: absolute;
  }
}
.requiredStar{
  margin-left: 6px;
  color: rgb(220, 53, 69);
}


.swal2-container.swal2-center > .swal2-popup {
  .swal2-cancel.btn {
    color: var(--bs-btn-color);
  }
}


/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
ul.dragula{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  > li{
    line-height: 1.5rem;
  }
}